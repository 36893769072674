/* istanbul ignore file */

import cx from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { HeadingDataFragment } from "../../../graphql-types";

type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type Props = HeadingDataFragment;

export default function Heading({ level, title }: Props) {
  const Tag = `h${level}` as HeadingTag;
  return (
    <div
      className={cx("relative px-4 text-center", {
        "mt-12 mb-6": level === 1, // gap between heading and the immediately preceding section (assuming there is one)
        "mt-12": level === 2, // gap between heading and the immediately preceding section (assuming there is one)
      })}
    >
      {level === 2 ? (
        // full-width page divider
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-8 h-24 bg-gradient-to-b from-gray-100"
        />
      ) : null}
      <Tag
        className={cx(
          "relative inline-block text-primary-500 font-heading font-extrabold leading-none tracking-tight group max-w-lg mx-auto",
          {
            "text-4xl bg-bottom bg-gradient-to-r from-secondary-500 to-secondary-500 bg-no-repeat bg-[length:3rem_1px] transition-[background-image] duration-200 ease-out pb-5":
              level === 1 || level === 2,
            "text-3xl": level === 3,
          }
        )}
      >
        {title}
      </Tag>
    </div>
  );
}

export const fragment = graphql`
  fragment HeadingData on Heading {
    title
    level
  }
`;
